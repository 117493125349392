import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import moment from 'moment';
import {
  ACCESS,
  ACCESS_SECURITY,
  SERVICE,
  INCIDENT,
  CHANGE,
  KEY,
  FILTER_REQUESTS,
  CLEAR_FILTER,
  RESET_FILTER,
  RETURN_FILTER,
  REQUEST_LOADING,
  SET_REQUEST,
  START,
  END,
  ON_SORT,
  APPLY_QUERY_PARAMS_TO_TABLE,
  FILTER_NOTIFICATION_REQUESTS,
  CLEAR_NOTIFICATION_FILTER,
  SET_INCIDENT_DETAILS,
} from '../action-constants';
import { ALL_OR_NO_SELECTION } from '../../util/constants';

// Filter whitelists determine which query params may be permitted for sorting and filtering.
const DEFAULT_QUERY_PARAM_WHITELIST = [
  'sortBy',
  'direction',
  'ticketNumber',
  'customerId',
  'dataCentreId',
  'siteVisitors',
  'additionalVisitors',
  'status',
  'fromDateStart',
  'fromDateEnd',
  'createdDateStart',
  'createdDateEnd',
  'accessReason',
  'actions',
  'internalGroup'
];

const ACCESS_QUERY_PARAM_WHITELIST = [
  ...DEFAULT_QUERY_PARAM_WHITELIST,
  'toDateStart',
  'toDateEnd',
  'securityStatus',
];

const KEY_QUERY_PARAM_WHITELIST = [
  'sortBy',
  'direction',
  'ticketNumber',
  'customerId',
  'dataCentreId',
  'status',
  'requester',
  'createdDateStart',
  'createdDateEnd',
  'accessReason',
  'requester',
  'keyId',
  'checkedInDateStart',
  'checkedInDateEnd',
  'checkedOutDateStart',
  'checkedOutDateEnd',
];

const DEFAULT_INCIDENT_QUERY_PARAM_WHITELIST = [
  'sortBy',
  'direction',
  'ticketNumber',
  'dataCentreId',
  'status',
  'ticketType',
  'buOwner',
  'impact'
];

const DEFAULT_CHANGE_QUERY_PARAM_WHITELIST = [
  'sortBy',
  'direction',
  'customerId',
  'ticketNumber',
  'dataCentreId',
  'status',
  'internalGroup',
  'actionType'
];

const MULTISELECT_PARAMS = [
  'status',
  'accessReason',
  'securityStatus',
  'actions',
  'internalGroup',
  'actionType',
  'ticketType',
  'buOwner',
  'impact'
];

const DEFAULT_DATE_FILTER = {
  start: null,
  end: null,
};

const INCIDENT_DEFAULT_FILTERS = {
  ticketNumber: '',
  ticketType: ALL_OR_NO_SELECTION,
  title: '',
  dataCentre: null,
  status: ALL_OR_NO_SELECTION,
  buOwner: ALL_OR_NO_SELECTION,
  impact: ALL_OR_NO_SELECTION,
  createdDate: DEFAULT_DATE_FILTER,
  estimatedDate: DEFAULT_DATE_FILTER,
  detectionDate: DEFAULT_DATE_FILTER,
  resolvedDate: DEFAULT_DATE_FILTER,
};

const CHANGE_DEFAULT_FILTERS = {
  ticketNumber: '',
  dataCentre: null,
  customer: null,
  status: ALL_OR_NO_SELECTION,
  createdDate: DEFAULT_DATE_FILTER,
  scheduledDate: DEFAULT_DATE_FILTER,
  actionType: ALL_OR_NO_SELECTION,
  internalGroup: ALL_OR_NO_SELECTION,
  internalAssignee: '',
};

const DEFAULT_FILTERS = {
  ticketNumber: '',
  customer: null,
  dataCentre: null,
  siteVisitors: '',
  additionalVisitors: '',
  accessReason: ALL_OR_NO_SELECTION,
  accessZones: null,
  status: ALL_OR_NO_SELECTION,
  fromDate: DEFAULT_DATE_FILTER,
  createdDate: DEFAULT_DATE_FILTER,
  internalGroup: ALL_OR_NO_SELECTION,
  actions: ALL_OR_NO_SELECTION,
};

const KEY_FILTERS = {
  ticketNumber: '',
  status: ALL_OR_NO_SELECTION,
  requester: null,
  manualRequester: null,
  keys: null,
  createdDate: DEFAULT_DATE_FILTER,
  checkedInDate: DEFAULT_DATE_FILTER,
  checkedOutDate: DEFAULT_DATE_FILTER,
  customer: null,
  dataCentre: null,
};

const ACCESS_DEFAULT_FILTERS = {
  ...DEFAULT_FILTERS,
  toDate: DEFAULT_DATE_FILTER,
  securityStatus: ALL_OR_NO_SELECTION,
};

const initialState = {
  [ACCESS]: {
    filters: ACCESS_DEFAULT_FILTERS,
    data: [],
    isLoading: true,
    total: 0,
    sort: {
      columnId: 'created_at',
      direction: 'desc',
    },
  },
  [ACCESS_SECURITY]: {
    filters: { ticketNumber: '',
    customer: null,
    dataCentre: null,
    siteVisitors: '',
    additionalVisitors: '',
    accessReason: ALL_OR_NO_SELECTION,
    accessZones: null,
    status: ['accepted'],
    fromDate: DEFAULT_DATE_FILTER,
    createdDate: DEFAULT_DATE_FILTER,
    internalGroup: ALL_OR_NO_SELECTION,
    actions: ALL_OR_NO_SELECTION,
    toDate: DEFAULT_DATE_FILTER,
    securityStatus: ALL_OR_NO_SELECTION,
  },
    data: [],
    isLoading: true,
    total: 0,
    sort: {
      columnId: 'created_at',
      direction: 'desc',
    },
  },
  [SERVICE]: {
    filters: DEFAULT_FILTERS,
    data: [],
    isLoading: true,
    total: 0,
    sort: {
      columnId: 'created_at',
      direction: 'desc',
    },
  },
  [KEY]: {
    filters: KEY_FILTERS,
    data: [],
    isLoading: true,
    total: 0,
    sort: {
      columnId: 'checked_out_date',
      direction: 'desc',
    },
  },
  [INCIDENT]: {
    filters: INCIDENT_DEFAULT_FILTERS,
    data: [],
    incidentData: [],
    isLoading: true,
    total: 0,
    sort: {
      columnId: 'created_at',
      direction: 'desc',
    },
  },
  [CHANGE]: {
    filters: CHANGE_DEFAULT_FILTERS,
    data: [],
    isLoading: true,
    total: 0,
    sort: {
      columnId: 'created_at',
      direction: 'desc',
    },
  },
  dashBoardNotificationType: '',
};

const filterRequests = (state, action) => {
  const {
    requestType, payload, filter, params,
  } = action;
  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      filters: {
        ...state[requestType].filters,
        [filter]: (params === START || params === END) ? {
          ...state[requestType].filters[filter],
          [params]: payload,
        } : payload,
      },
    },
  };
};

const clearFilter = (state, action) => {
  const { requestType, filter } = action;
  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      filters: {
        ...state[requestType].filters,
        [filter]: '',
      },
    },
  };
};

const changeNotificationFilterRequests = (state, action) => {
  const {
    dashBoardNotificationType,
  } = action;
  return {
    ...state,
    dashBoardNotificationType,
  };
};

const clearNotificationRequestFilter = (state, action) => {
  const { dashBoardNotificationType } = action;
  return {
    ...state,
    dashBoardNotificationType: '',
  };
};

const setLoading = (state, action) => {
  const { requestType, payload } = action;
  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      isLoading: payload,
    },
  };
};

const setRequest = (state, action) => {
  const { payload, requestType } = action;
  const { data, total } = payload;
  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      data,
      total,
    },
  };
};

const setIncidentDetails = (state, action) => {
  const { payload } = action;
  const {
    impact, customerIds, includeInternalNotifications,
    customersSelected, customersNames, affectedType, underInvestigation,
  } = payload;
  return {
    ...state,
    incident: {
      ...state.incident,
      incidentData: {
        impact,
        customer_ids: customerIds,
        customer_names: customersNames,
        customers_selected: customersSelected,
        include_internal_notifications: includeInternalNotifications,
        affected_types: affectedType,
        underInvestigation,
      },
    },
  };
};

const resetFilter = (state, action) => {
  const { requestType } = action;

  const getDefaultFilters = requestType => {
    if (requestType === ACCESS || requestType === ACCESS_SECURITY) {
      return ACCESS_DEFAULT_FILTERS;
    }
    if (requestType === INCIDENT) {
      return INCIDENT_DEFAULT_FILTERS;
    }
    if (requestType === SERVICE) {
      return ACCESS_DEFAULT_FILTERS;
    }
    if (requestType === CHANGE) {
      return CHANGE_DEFAULT_FILTERS;
    }
    return KEY_FILTERS;
  };

  const filters = {
    ...getDefaultFilters(requestType),
    status: ALL_OR_NO_SELECTION,
    securityStatus: ALL_OR_NO_SELECTION,
  };

  // DCAP-26
  let colId;
  requestType === 'key' ? colId = 'checked_out_date' : colId = 'created_at';

  // DCAP-26: to reset the 'sort by' to the "created_at" or "checked_out_date" in ascending order
  //          when the 'reset filter' button is fired from the UI panel
  const newSort = {
    columnId: colId,
    direction: 'desc',
  };

  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      filters,
      sort: newSort, // DCAP-26
    },
  };
};

const returnFilter = (state, action) => {
  const { requestType } = action;
  const newSort = {
    columnId: state[requestType].sort.columnId,
    direction: state[requestType].sort.direction,
  };

  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      sort: newSort,
    },
  };
};

const onSort = (state, action) => {
  const { columnId, requestType } = action;

  let direction = 'asc';
  if (state[requestType].sort.columnId === columnId) {
    direction = state[requestType].sort.direction === 'asc' ? 'desc' : 'asc';
  }

  let newSort = {
    columnId,
    direction,
  };

  if (state[requestType].sort.columnId === columnId) {
    if (state[requestType].sort.direction === 'asc') {
      newSort = {
        columnId,
        direction: 'desc',
      };
    }
  } else {
    newSort = {
      columnId,
      direction: 'asc',
    };
  }

  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      sort: newSort,
    },
  };
};

const applyQueryParamsToIncidentTable = (state, action) => {
  const { requestType, queryParams, additionalData } = action;

  // Determine which whitelist to use based on the type of request table.
  const queryParamWhitelist = DEFAULT_INCIDENT_QUERY_PARAM_WHITELIST;

  // Get the current sort configuration for the table.
  const { sort: currentSort } = state[requestType];

  // Create objects to use for the the new configuration.
  // (Copy default filters so that the only filters applied are those provided by query params)
  const newSort = { ...currentSort };
  // Ensure deep clone.
  const newFilters = JSON.parse(JSON.stringify(INCIDENT_DEFAULT_FILTERS));

  // Separate the sorting params from the remaining query parameters.
  const { sortBy, direction, ...remainingParams } = queryParams;

  // Set each sort option if provided, otherwise use existing options.
  newSort.columnId = sortBy || currentSort.columnId;
  newSort.direction = direction || currentSort.direction;

  // Check each of the remaining params and add filters where applicable.
  Object.keys(remainingParams).forEach(paramKey => {
    // Only add the filter if included in the whitelist.
    if (queryParamWhitelist.includes(paramKey)) {
      // Handle query params that don't directly correspond to a filter.
      // E.g. 'status' directly corresponds to the 'status' filter, but 'createdDateStart'
      // indirectly corresponds to the 'createdDate' filter.
      if ([
        'dataCentreId',
        'createdDateStart',
        'createdDateEnd',
        'resolvedDateStart',
        'resolvedDateEnd',
      ].includes(paramKey)) {
        const value = remainingParams[paramKey];
        if (paramKey === 'dataCentreId') {
          newFilters.dataCentre = additionalData.dataCentreId ? {
            name: additionalData.dataCentreId.name,
            id: value,
          } : null;
        } else if (paramKey === 'createdDateStart') {
          newFilters.createdDate.start = value ? moment(value, 'DD-MM-YYYY') : null;
        } else if (paramKey === 'createdDateEnd') {
          newFilters.createdDate.end = value ? moment(value, 'DD-MM-YYYY') : null;
        } else if (paramKey === 'resolvedDateStart') {
          newFilters.resolvedDate.start = value ? moment(value, 'DD-MM-YYYY') : null;
        } else if (paramKey === 'resolvedDateEnd') {
          newFilters.resolvedDate.end = value ? moment(value, 'DD-MM-YYYY') : null;
        }
      } else if (MULTISELECT_PARAMS.includes(paramKey)) {
        // Ensure multiselect fields are ALWAYS of type ARRAY
        newFilters[paramKey] = Array.isArray(remainingParams[paramKey]) ? remainingParams[paramKey] : [remainingParams[paramKey]]
      } else {
        // Otherwise directly set the filter value.
        newFilters[paramKey] = remainingParams[paramKey];
      }
    } else {
      console.log(`Unknown query param: ${paramKey}`);
    }
  });

  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      sort: newSort,
      filters: newFilters,
    },
  };
};

const getNewFilters = requestType => {
  if (requestType === ACCESS || requestType === ACCESS_SECURITY) {
    return ACCESS_DEFAULT_FILTERS;
  }
  if (requestType === SERVICE) {
    return DEFAULT_FILTERS;
  }
  if (requestType === CHANGE) {
    return CHANGE_DEFAULT_FILTERS;
  }
  return KEY_FILTERS;
};

const getQueryParamsWhiteList = requestType => {
  if (requestType === ACCESS || requestType === ACCESS_SECURITY) {
    return ACCESS_QUERY_PARAM_WHITELIST;
  }
  if (requestType === SERVICE) {
    return DEFAULT_QUERY_PARAM_WHITELIST;
  }
  if (requestType === CHANGE) {
    return DEFAULT_CHANGE_QUERY_PARAM_WHITELIST;
  }
  return KEY_QUERY_PARAM_WHITELIST;
};

const applyQueryParamsToTable = (state, action) => {
  const { requestType, queryParams, additionalData } = action;

  // Determine which whitelist to use based on the type of request table.
  const queryParamWhitelist = getQueryParamsWhiteList(requestType);

  // Get the current sort configuration for the table.
  const { sort: currentSort } = state[requestType];

  // Create objects to use for the the new configuration.
  // (Copy default filters so that the only filters applied are those provided by query params)
  const newSort = { ...currentSort };
  // Ensure deep clone.
  const newFilters = JSON.parse(JSON.stringify(getNewFilters(requestType)));

  // Separate the sorting params from the remaining query parameters.
  const { sortBy, direction, ...remainingParams } = queryParams;

  // Set each sort option if provided, otherwise use existing options.
  newSort.columnId = sortBy || currentSort.columnId;
  newSort.direction = direction || currentSort.direction;

  // Check each of the remaining params and add filters where applicable.
  Object.keys(remainingParams).forEach(paramKey => {
    // Only add the filter if included in the whitelist.
    if (queryParamWhitelist.includes(paramKey)) {
      // Handle query params that don't directly correspond to a filter.
      // E.g. 'status' directly corresponds to the 'status' filter, but 'createdDateStart'
      // indirectly corresponds to the 'createdDate' filter.
      if ([
        'customerId',
        'dataCentreId',
        'keyId',
        'fromDateStart',
        'fromDateEnd',
        'createdDateStart',
        'createdDateEnd',
        'toDateStart',
        'toDateEnd',
        'checkedInDateStart',
        'checkedInDateEnd',
        'checkedOutDateStart',
        'checkedOutDateEnd',
        'scheduledDateStart',
        'scheduledDateEnd',
      ].includes(paramKey)) {
        const value = remainingParams[paramKey];
        if (paramKey === 'customerId') {
          newFilters.customer = additionalData.customerId ? {
            name: additionalData.customerId.name,
            id: value,
          } : null;
        } else if (paramKey === 'dataCentreId') {
          newFilters.dataCentre = additionalData.dataCentreId ? {
            name: additionalData.dataCentreId.name,
            id: value,
          } : null;
        } else if (paramKey === 'keyId') {
          newFilters.keys = additionalData.keyId ? {
            name: additionalData.keyId.name,
            id: value,
          } : null;
        } else if (paramKey === 'fromDateStart') {
          newFilters.fromDate.start = value ? moment(value, 'DD-MM-YYYY') : null;
        } else if (paramKey === 'fromDateEnd') {
          newFilters.fromDate.end = value ? moment(value, 'DD-MM-YYYY') : null;
        } else if (paramKey === 'checkedInDateStart') {
          newFilters.checkedInDate.start = value ? moment(value, 'DD-MM-YYYY') : null;
        } else if (paramKey === 'checkedInDateEnd') {
          newFilters.checkedInDate.end = value ? moment(value, 'DD-MM-YYYY') : null;
        } else if (paramKey === 'createdDateStart') {
          newFilters.createdDate.start = value ? moment(value, 'DD-MM-YYYY') : null;
        } else if (paramKey === 'createdDateEnd') {
          newFilters.createdDate.end = value ? moment(value, 'DD-MM-YYYY') : null;
        } else if (paramKey === 'toDateStart') {
          newFilters.toDate.start = value ? moment(value, 'DD-MM-YYYY') : null;
        } else if (paramKey === 'toDateEnd') {
          newFilters.toDate.end = value ? moment(value, 'DD-MM-YYYY') : null;
        } else if (paramKey === 'checkedOutDateStart') {
          newFilters.checkedOutDate.start = value ? moment(value, 'DD-MM-YYYY') : null;
        } else if (paramKey === 'checkedOutDateEnd') {
          newFilters.checkedOutDate.end = value ? moment(value, 'DD-MM-YYYY') : null;
        } else if (paramKey === 'scheduledDateStart') {
          newFilters.scheduledDate.start = value ? moment(value, 'DD-MM-YYYY') : null;
        } else if (paramKey === 'scheduledDateEnd') {
          newFilters.scheduledDate.end = value ? moment(value, 'DD-MM-YYYY') : null;
        }
      } else if (MULTISELECT_PARAMS.includes(paramKey)) {
        // Ensure multiselect fields are ALWAYS of type ARRAY
        newFilters[paramKey] = Array.isArray(remainingParams[paramKey]) ? remainingParams[paramKey] : [remainingParams[paramKey]]
      } else {
        // Otherwise directly set the filter value.
        newFilters[paramKey] = remainingParams[paramKey];
      }
    } else {
      // TODO: Log unknown query params with Sentry.
      console.log(`Unknown query param: ${paramKey}`);
    }
  });

  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      sort: newSort,
      filters: newFilters,
    },
  };
};

export default (state = initialState, action) => {
  const { requestType } = action;

  switch (action.type) {
    case FILTER_REQUESTS: {
      return filterRequests(state, action);
    }
    case CLEAR_FILTER: {
      return clearFilter(state, action);
    }
    case FILTER_NOTIFICATION_REQUESTS: {
      return changeNotificationFilterRequests(state, action);
    }
    case CLEAR_NOTIFICATION_FILTER: {
      return clearNotificationRequestFilter(state, action);
    }
    case RESET_FILTER: {
      return resetFilter(state, action);
    }
    case RETURN_FILTER: {
      return returnFilter(state, action);
    }
    case REQUEST_LOADING: {
      return setLoading(state, action);
    }
    case SET_REQUEST: {
      return setRequest(state, action);
    }
    case SET_INCIDENT_DETAILS: {
      return setIncidentDetails(state, action);
    }
    case ON_SORT: {
      return onSort(state, action);
    }
    case APPLY_QUERY_PARAMS_TO_TABLE: {
      return (requestType === INCIDENT ? applyQueryParamsToIncidentTable(state, action) : applyQueryParamsToTable(state, action));
    }
    default:
      return state;
  }
};

import React, { useState } from 'react';
import './App.css';
import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core';
import { PersistGate } from 'redux-persist/integration/react';

import { Provider } from 'react-redux';
import Cookies from 'universal-cookie';
import Amplify from 'aws-amplify';
import configureStore from './redux/store';
import awsConfig from './aws-exports';
import AppContent from './layout/AppContent';

const { store, persistor } = configureStore();

Amplify.configure(awsConfig);

/**
 * Refer to the following MUI theme structure,
 * https://mui.com/material-ui/customization/default-theme
 * Use this structure whenever creating a new theme. If you
 * are required to use a custom variable, put it within the
 * 'custom' block.
 */

const darkTheme = responsiveFontSizes(createMuiTheme({
  palette: {
    mode: 'dark',
    type: 'dark',
    primary: {
      main: '#FF0070',
    },
    secondary: {
      main: '#FF0070',
    },
    error: {
      main: '#FA0000',
    },
    warning: {
      main: '#F0B02C',
    },
    success: {
      main: '#40B549',
    },
    grey: {
      50: '#E6E7E8',
      100: '#CED0D2',
      200: '#B3B6B9',
      300: '#33417E', // OPEN CUSTOMER DB button
      400: '#80858A',
      500: '#6D7379',
      600: '#61676E',
      700: '#4D545B', // chip elements, use for cards aswell
      800: '#2D363F',
      900: '#1A232C',
      A100: '#CED0D2', // 300: button hover
      A200: '#B3B6B9',
      A400: '#80858A',
      A700: '#4D545B',
    },
    background: {
      paper: '#222E3A',
      default: '#0A1017',
      tertiary: '#111922',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B3B6B9',
      disabled: '#4D545B',
    },
    custom: {
      // variables here are specific to particular components
      // not part of MUI
      cBlue: '#002BFE',
      cEscort: '#800080',
      cRequestsPanel: '#1A232C',
    },
  },
}));

const lightTheme = responsiveFontSizes(createMuiTheme({
  palette: {
    mode: 'light',
    type: 'light',
    primary: {
      main: '#002BFE',
    },
    secondary: {
      main: '#FF0070',
    },
    error: {
      main: '#CF6679',
    },
    warning: {
      main: '#F0B02C',
    },
    success: {
      main: '#40B549',
    },
    // grey: {} use default
    background: {
      paper: '#FAFAFA',
      default: '#FFFFFF',
      tertiary: '#FAFAFA',
    },
    text: {
      primary: '#0A1017',
      secondary: '#4D545B',
      disabled: '#80858A',
    },
    custom: {
      // variables here are specific to particular components
      // not part of MUI
      cBlue: '#002BFE',
      cEscort: '#800080',
      cRequestsPanel: '#FFFFFF',
    },
  },
}));

function App() {
  const cookies = new Cookies();
  const [darkMode, setDarkMode] = useState(cookies.get('darkMode')
    ? Boolean(JSON.parse(cookies.get('darkMode'))) : false);

  const toggleTheme = () => {
    const cookieDark = cookies.get('darkMode');
    const toggleDark = cookieDark !== undefined ? !JSON.parse(cookieDark) : true;
    cookies.set('darkMode', toggleDark, { path: '/' });
    setDarkMode(toggleDark);
  };

  return (
    <Provider store={ store }>
      <PersistGate loading={ null } persistor={ persistor }>
        <ThemeProvider theme={ darkMode ? darkTheme : lightTheme }>
          <AppContent toggleTheme={ toggleTheme } darkMode={ darkMode } />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;

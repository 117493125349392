import React from 'react';
import { makeStyles } from '@material-ui/core';
import LoginButton from './shared/LoginButton';

const useStyles = makeStyles(theme => ({
  container: {
    // backgroundColor: theme.palette.background.primary,
  },
}));

function Login() {
  const classes = useStyles();
  return (
    <div className={ `flex-container-center ${classes.container}` }>
      <LoginButton color='primary' size='large' variant='contained' />
    </div>
  );
}

export default Login;

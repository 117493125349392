import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    // backgroundColor: theme.palette.background.primary,
    flexDirection: 'column',
  },
  color: {
    color: theme.palette.text.primary,
  },
}));

function Error() {
  const classes = useStyles();
  return (
    <Box className={ `flex-container-center ${classes.container}` } data-testid='error-id'>
      <Typography
        variant='h1'
        gutterBottom
        className={ classes.color }
      >
        Oops, an error has occurred
      </Typography>
      <Typography
        variant='h4'
        className={ classes.color }
      >
        Please contact your system administrator or help desk
      </Typography>
    </Box>
  );
}

export default Error;

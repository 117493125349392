import { ACCESS, ACCESS_SECURITY, NOTIFICATION, SERVICE, KEY, INCIDENT, CHANGE } from './action-constants';

// Requests
export const accessFiltersSelector = state => state.requests[ACCESS].filters;
export const accessRequestsSelector = state => state.requests[ACCESS].data;
export const accessLoadingSelector = state => state.requests[ACCESS].isLoading;
export const accessTotalSelector = state => state.requests[ACCESS].total;

export const accessSecurityFiltersSelector = state => state.requests[ACCESS_SECURITY].filters;
export const accessSecurityRequestsSelector = state => state.requests[ACCESS_SECURITY].data;
export const accessSecurityLoadingSelector = state => state.requests[ACCESS_SECURITY].isLoading;
export const accessSecurityTotalSelector = state => state.requests[ACCESS_SECURITY].total;

export const serviceFiltersSelector = state => state.requests[SERVICE].filters;
export const serviceRequestsSelector = state => state.requests[SERVICE].data;
export const serviceLoadingSelector = state => state.requests[SERVICE].isLoading;
export const serviceTotalSelector = state => state.requests[SERVICE].total;

export const keyFiltersSelector = state => state.requests[KEY].filters;
export const keyRequestsSelector = state => state.requests[KEY].data;
export const keyLoadingSelector = state => state.requests[KEY].isLoading;
export const keyTotalSelector = state => state.requests[KEY].total;

export const incidentFiltersSelector = state => state.requests[INCIDENT].filters;
export const incidentRequestsImpactSelector = state => state.requests[INCIDENT].incidentData;
export const incidentRequestsSelector = state => state.requests[INCIDENT].data;
export const incidentLoadingSelector = state => state.requests[INCIDENT].isLoading;
export const incidentTotalSelector = state => state.requests[INCIDENT].total;
export const incidentSelectedCustomersSelector = state => state.requests[INCIDENT].incidentData.customers_selected;

export const changeFiltersSelector = state => state.requests[CHANGE].filters;
export const changeRequestsSelector = state => state.requests[CHANGE].data;
export const changeLoadingSelector = state => state.requests[CHANGE].isLoading;
export const changeTotalSelector = state => state.requests[CHANGE].total;

export const ticketNumberFilter = (state, requestType) => state.requests[requestType].filters.ticketNumber;
export const customerFilter = (state, requestType) => state.requests[requestType].filters.customer;
export const dataCentreFilter = (state, requestType) => state.requests[requestType].filters.dataCentre;
export const siteVisitorFilter = (state, requestType) => state.requests[requestType].filters.siteVisitors;
export const additionalVisitorFilter = (state, requestType) => state.requests[requestType].filters.additionalVisitors;
export const accessZonesFilter = (state, requestType) => state.requests[requestType].filters.accessZones;
export const createdDateFilter = (state, requestType) => state.requests[requestType].filters.createdDate;
export const createdDateStartFilter = (state, requestType) => state.requests[requestType].filters.createdDate.start;
export const createdDateEndFilter = (state, requestType) => state.requests[requestType].filters.createdDate.end;
export const fromDateFilter = (state, requestType) => state.requests[requestType].filters.fromDate;
export const fromDateStartFilter = (state, requestType) => state.requests[requestType].filters.fromDate.start;
export const fromDateEndFilter = (state, requestType) => state.requests[requestType].filters.fromDate.end;
export const toDateFilter = (state, requestType) => state.requests[requestType].filters.toDate;
export const toDateStartFilter = (state, requestType) => state.requests[requestType].filters.toDate.start;
export const toDateEndFilter = (state, requestType) => state.requests[requestType].filters.toDate.end;
export const resolvedDateStartFilter = (state, requestType) => state.requests[requestType].filters.resolvedDate.start;
export const resolvedDateEndFilter = (state, requestType) => state.requests[requestType].filters.resolvedDate.end;
export const statusFilter = (state, requestType) => state.requests[requestType].filters.status;
export const securityStatusFilter = (state, requestType) => state.requests[requestType].filters.securityStatus;
export const airtrunkAccessNumberFilter = (state, requestType) => state.requests[requestType].filters.airtrunkAccessNumber;
export const accessReasonFilter = (state, requestType) => state.requests[requestType].filters.accessReason;
export const buOwnerFilter = (state, requestType) => state.requests[requestType].filters.buOwner;
export const actionTypeFilter = (state, requestType) => state.requests[requestType].filters.actionType;
export const ticketTypeFilter = (state, requestType) => state.requests[requestType].filters.ticketType;
export const impactTypeFilter = (state, requestType) => state.requests[requestType].filters.impact;
export const titleFilter = (state, requestType) => state.requests[requestType].filters.title;
export const creationDateFilter = (state, requestType) => state.requests[requestType].filters.creationDate;
export const creationDateStartFilter = (state, requestType) => state.requests[requestType].filters.creationDate.start;
export const creationDateEndFilter = (state, requestType) => state.requests[requestType].filters.creationDate.end;
export const scheduledDateFilter = (state, requestType) => state.requests[requestType].filters.scheduledDate;
export const scheduledDateStartFilter = (state, requestType) => state.requests[requestType].filters.scheduledDate.start;
export const scheduledDateEndFilter = (state, requestType) => state.requests[requestType].filters.scheduledDate.end;
export const internalAssigneeFilter = (state, requestType) => state.requests[requestType].filters.internalAssignee;
export const internalGroupFilter = (state, requestType) => state.requests[requestType].filters.internalGroup;
export const actionsFilter = (state, requestType) => state.requests[requestType].filters.actions;
export const racksFilter = (state, requestType) => state.requests[requestType].filters.racks;

export const requestNumberFilter = (state, requestType) => state.requests[requestType].filters.ticketNumber;
export const keyStatusFilter = (state, requestType) => state.requests[requestType].filters.status;
export const requesterFilter = (state, requestType) => state.requests[requestType].filters.requester;
export const keyLockerFilter = (state, requestType) => state.requests[requestType].filters.keys;
export const dateInStartFilter = (state, requestType) => state.requests[requestType].filters.checkedInDate.start;
export const dateInEndFilter = (state, requestType) => state.requests[requestType].filters.checkedInDate.end;
export const dateOutStartFilter = (state, requestType) => state.requests[requestType].filters.checkedOutDate.start;
export const dateOutEndFilter = (state, requestType) => state.requests[requestType].filters.checkedOutDate.end;

export const sortSelector = (state, requestType) => state.requests[requestType].sort;

export const accessContactsSelector = state => state.requests[ACCESS].contacts.data;
export const accessContactsLoadingSelector = state => state.requests[ACCESS].contacts.isLoading;
export const accessContactsLoadedSelector = state => state.requests[ACCESS].contacts.isLoaded;
export const accessSecurityContactsSelector = state => state.requests[ACCESS_SECURITY].contacts.data;
export const accessSecurityContactsLoadingSelector = state => state.requests[ACCESS_SECURITY].contacts.isLoading;
export const accessSecurityContactsLoadedSelector = state => state.requests[ACCESS_SECURITY].contacts.isLoaded;
export const incidentStatusSelector = state => state.statuses[INCIDENT];

// Data - Drawer item
export const drawerIdSelector = state => state.data.drawerId;
export const drawerItemSelector = state => state.data.drawerItem;

// Data - Customer
export const customersSelector = state => state.data.customers.data;
export const customersLoadingSelector = state => state.data.customers.isLoading;
export const customersLoadedSelector = state => state.data.customers.isLoaded;

// Data - Data Centres
export const dataCentresSelector = state => state.data.dataCentres.data;
export const dataCentresLoadingSelector = state => state.data.dataCentres.isLoading;
export const dataCentresLoadedSelector = state => state.data.dataCentres.isLoaded;

// App
export const authStateSelector = state => state.app.authState;
export const notificationsSelector = state => state.app.notifications;
export const dashboardNotificationsSelector = state => state.requests.dashBoardNotificationType;
export const dashboardSelector = state => state.app.dashboard;
export const securityGroupSelector = state => state.app.securityGroup;

// Statuses
export const statusesLoadingSelector = state => state.statuses.isLoading;
export const accessStatusSelector = state => state.statuses[ACCESS];
export const accessSecurityStatusSelector = state => state.statuses[ACCESS_SECURITY];
export const serviceStatusSelector = state => state.statuses[SERVICE];
export const notificationStatusSelector = state => state.statuses[NOTIFICATION];
export const keyStatusSelector = state => state.statuses[KEY];

export const FILTER_REQUESTS = 'FILTER_REQUESTS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const FILTER_NOTIFICATION_REQUESTS = 'FILTER_NOTIFICATION_REQUESTS';
export const CLEAR_NOTIFICATION_FILTER = 'CLEAR_NOTIFICATION_FILTER';
export const FILTER_TICKET_NUMBER = 'FILTER_TICKET_NUMBER';
export const CLEAR_TICKET_NUMBER = 'CLEAR_TICKET_NUMBER';
export const FILTER_CUSTOMER = 'FILTER_CUSTOMER';
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';
export const FILTER_DATA_CENTRE = 'FILTER_DATA_CENTRE';
export const FILTER_STATUS = 'FILTER_STATUS';
export const FILTER_FROM_DATE = 'FILTER_FROM_DATE';
export const FILTER_TO_DATE = 'FILTER_TO_DATE';
export const REQUEST_LOADING = 'REQUEST_LOADING';
export const SET_REQUEST = 'SET_REQUEST';
export const SET_DRAWER_ITEM = 'SET_DRAWER_ITEM';
export const SET_DRAWER_ID = 'SET_DRAWER_ID';
export const RESET_FILTER = 'RESET_FILTER';
export const RETURN_FILTER = 'RETURN_FILTER';
export const SET_AUTH_STATE = 'SET_AUTH_STATE';
export const SET_STATUSES_LOADING = 'SET_STATUSES_LOADING';
export const SET_STATUSES = 'SET_STATUSES';
export const ON_SORT = 'ON_SORT';
export const APPLY_QUERY_PARAMS_TO_TABLE = 'APPLY_QUERY_PARAMS_TO_TABLE';
export const DASHBOARD_SELECTED = 'DASHBOARD_SELECTED';
export const SECURITY_GROUP_SELECTED = 'SECURITY_GROUP_SELECTED';
export const SET_INCIDENT_DETAILS = 'SET_INCIDENT_DETAILS';

// reducer state constants
export const ACCESS = 'access';
export const ACCESS_SECURITY = 'access_security';
export const SERVICE = 'service';
export const INCIDENT = 'incident';
export const CHANGE = 'change';
export const KEY = 'key';
export const ACCESS_REQUEST = 'access_request';
export const SERVICE_REQUEST = 'service_request';
export const INCIDENT_REQUEST = 'incident_request';
export const CHANGE_REQUEST = 'change_request';
export const KEY_REQUEST = 'key_request';
export const MAINTENANCE = 'maintenance';
export const NOTIFICATION = 'notification';

export const TICKET_NUMBER = 'ticketNumber';
export const CUSTOMER = 'customer';
export const DATA_CENTRE = 'dataCentre';
export const CREATED_DATE = 'createdDate';
export const SCHEDULED_DATE = 'scheduledDate';
export const FROM_DATE = 'fromDate';
export const TO_DATE = 'toDate';
export const STATUS = 'status';
export const SECURITY_STATUS = 'securityStatus';
export const AIRTRUNK_ACCESS_NUMBER = 'airtrunkAccessNumber';
export const EQUINIX_ACCESS_NUMBER = 'equinixAccessNumber';
export const SITE_VISITORS = 'siteVisitors';
export const ADDITIONAL_VISITORS = 'additionalVisitors';
export const ACCESS_ZONES = 'accessZones';
export const ACCESS_REASON = 'accessReason';
export const BU_OWNER = 'buOwner';
export const ACTION_TYPE = 'actionType';
export const ACTION = 'action';
export const TICKET_TYPE = 'ticketType';
export const IMPACT_TYPE = 'impact';
export const RESOLVED_DATE = 'resolvedDate';
export const TITLE = 'title';
export const INTERNAL_GROUP = 'internalGroup';

export const REQUEST_NUMBER = 'requestNumber';
export const REQUESTER = 'requester';
export const INTERNAL_ASSIGNEE = 'internalAssignee';
export const ACTIONS = 'actions';
export const RACKS = 'racks';
export const KEYS = 'keys';
export const CHECKED_IN_DATE = 'checkedInDate';
export const CHECKED_OUT_DATE = 'checkedOutDate';
// date filters
export const START = 'start';
export const END = 'end';

// Notistack
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const CLEAR_SNACKBAR = 'CLEAR_SNACKBAR';


import React, {
  useState, useEffect, Suspense, useCallback,
} from 'react';
import { useSelector, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  Brightness4Outlined, Brightness7,
} from '@material-ui/icons';
import {
  makeStyles,
  Tooltip,
  Fab,
  Tabs,
  Button,
  Tab,
} from '@material-ui/core/';
import {
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SecurityIcon from '@mui/icons-material/Security';
import BuildIcon from '@mui/icons-material/Build';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import KeyIcon from '@mui/icons-material/Key';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DescriptionIcon from '@mui/icons-material/Description';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  SECURITY_TEAM_USER, USER, ADMIN, VIEW_ONLY, SECURITY, OPERATIONAL, SUPERVISOR,
} from '../util/constants';
import { dashboardSelector, securityGroupSelector } from '../redux/selectors';
import { setSecurityGroup, setDashboard } from '../redux/actions/app';
import Loading from '../components/shared/Loading';
import ErrorBoundary from '../components/shared/ErrorBoundary';
import Error from '../components/shared/Error';
import {
  OPERATIONS_TABS, ADMIN_TABS, SECURITY_TABS,
} from './Tabs';

import DataCentresLogo from './DataCentresLogoDefault.png';
import DataCentresLogoWhite from './DataCentresLogoWhite.png';
import AdminPortalIcon from './AdminPortalIcon.svg';
import AdminPortalIconWhite from './AdminPortalIconWhite.svg';

const envColours = {
  DEV: {
    background: '#3BB2CC',
    divider: '#A5EFFF',
    shadow: 'rgb(0, 0, 0, 0.25)',
  },
  SYS: {
    background: '#FF5C00',
    divider: '#FFAE80',
    shadow: 'rgb(0, 0, 0, 0.05)',
  },
  UAT: {
    background: '#FF0070',
    divider: '#FF80B8',
    shadow: 'rgb(0, 0, 0, 0.05)',
  },
  PROD: {
    background: '#0a1839',
    divider: '#8089AF',
    shadow: 'rgb(0, 0, 0, 0.25)',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  navbar: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minWidth: '56px',
    width: '56px',
    zIndex: '3',
    overflow: 'hidden',
    color: 'white',
    backgroundColor: envColours[process.env.REACT_APP_CURRENT_ENV].background,
    // borderRight: `1px solid ${theme.palette.border.primary}`,
    boxShadow: 'none',
    transition: 'min-width 0.25s, box-shadow 0.25s',
    '&:hover': {
      minWidth: '256px',
      // boxShadow: `16px 0 48px ${envColours[process.env.REACT_APP_CURRENT_ENV].shadow}`
    },
  },
  navbarBackground: {
    animation: '$anim 10s infinite linear',
    position: 'absolute',
    zIndex: -1,
    height: '150%',
    width: '100%',
    backgroundImage: process.env.REACT_APP_CURRENT_ENV === 'UAT' && 'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxkZWZzPjxwYXR0ZXJuIGlkPSJwYXR0ZXJuX1ZPVlR4UCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgd2lkdGg9IjI5IiBoZWlnaHQ9IjI5IiBwYXR0ZXJuVHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48bGluZSB4MT0iMCIgeT0iMCIgeDI9IjAiIHkyPSIyOSIgc3Ryb2tlPSIjRkY0RThGIiBzdHJva2Utd2lkdGg9IjMwIi8+PC9wYXR0ZXJuPjwvZGVmcz4gPHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNwYXR0ZXJuX1ZPVlR4UCkiIG9wYWNpdHk9IjEiLz48L3N2Zz4=")',
  },
  '@keyframes anim': {
    '0%': {
      top: '-50%',
    },
    '100%': {
      top: '0',
    },
  },
  brandContainer: {
    display: 'flex',
    padding: '48px 16px 32px 16px',
    gap: '16px',
    alignItems: 'center',
    justfiyContent: 'space-between',
    width: '256px',
  },
  tabs: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  tab: {
    minWidth: '256px',
  },
  tabWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '16px',
    marginBottom: '0',
  },
  tabLabelIcon: {
    minHeight: '0',
    paddingTop: '0',
  },
  tabRoot: {
    padding: '8px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '16px',
    left: '8px',
  },
  versionInfo: {
    fontSize: '0.5rem',
    marginLeft: '56px',
    marginBottom: '16px',
    paddingRight: '16px',
    fontWeight: 400,
    lineHeight: 1.43,
    textAlign: 'center',
    minWidth: 'calc(256px - 56px - 16px)',
    textAlign: 'right',
  },
  logo: {
    maxHeight: '24px',
    fill: theme.palette.text.primary,
  },
  logo2: {
    maxWidth: 'calc(256px - 56px - 16px)',
  },
  routeContainer: {
    flex: 1,
    height: '100%',
    paddingLeft: '56px',
  },
  divider: {
    width: 'calc(100% - 32px)',
    margin: '16px auto',
    height: '1px',
    backgroundColor: envColours[process.env.REACT_APP_CURRENT_ENV].divider,
  },
}));

const icons = [
  <AccessTimeIcon />,
  <SecurityIcon />,
  <BuildIcon />,
  <PublishedWithChangesIcon />,
  <WarningAmberIcon />,
  <KeyIcon />,
  <NotificationsIcon />,
  <DescriptionIcon />,
];

const a11yProps = index => ({
  'aria-controls': `vertical-tabpanel-${index}`,
});

const renderTabComponent = component => {
  const Component = component;
  return <Component />;
};

const TabLayout = ({
  darkMode, toggleTheme, setSignOut, selectedSecurityGroup, setSecurityGroup,
  selectedDashboard, setDashboard,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const signOut = () => Auth.signOut(() => setSignOut()).catch(err => console.error(err));
  const currentSelectedSecurityGroup = useSelector(securityGroupSelector);

  const getTabs = () => {
    if (currentSelectedSecurityGroup === 'ADMIN' || currentSelectedSecurityGroup === 'SUPERVISOR') {
      return ADMIN_TABS;
    }
    if (currentSelectedSecurityGroup === 'SECURITY_TEAM_USER') {
      return SECURITY_TABS;
    }
    return OPERATIONS_TABS;
  };

  // Set the tab index when the route changes.
  useEffect(() => {
    const index = getTabs().findIndex(tab => tab.pathname === location.pathname);
    setTabIndex(index === -1 ? 0 : index);
  }, [location.pathname]);

  const setSecurityTab = useCallback(() => {
    if (selectedDashboard !== SECURITY) setDashboard(SECURITY);
  }, [selectedDashboard, setDashboard]);

  const setOperational = useCallback(() => {
    if (selectedDashboard !== OPERATIONAL) setDashboard(OPERATIONAL);
  }, [selectedDashboard, setDashboard]);

  const setSecurityUserGroup = useCallback(() => {
    if (selectedSecurityGroup !== SECURITY_TEAM_USER) setSecurityGroup(SECURITY_TEAM_USER);
  }, [selectedSecurityGroup, setSecurityGroup]);

  const setAdminGroup = useCallback(() => {
    if (selectedSecurityGroup !== ADMIN) setSecurityGroup(ADMIN);
  }, [selectedSecurityGroup, setSecurityGroup]);

  const setSupervisorGroup = useCallback(() => {
    if (selectedSecurityGroup !== SUPERVISOR) setSecurityGroup(SUPERVISOR);
  }, [selectedSecurityGroup, setSecurityGroup]);

  const setUserGroup = useCallback(() => {
    if (selectedSecurityGroup !== USER) setSecurityGroup(USER);
  }, [selectedSecurityGroup, setSecurityGroup]);

  const setCurrentGroup = useCallback(group => {
    setSecurityGroup(group);
  }, [selectedSecurityGroup, setSecurityGroup]);

  const setViewOnlyGroup = useCallback(() => {
    if (selectedSecurityGroup !== VIEW_ONLY) setSecurityGroup(VIEW_ONLY);
  }, [selectedSecurityGroup, setSecurityGroup]);

  const SECURITY_GROUPS = {
    '2838edf4-ab8e-4506-9427-9f04a2777e9b': 'SECURITY_TEAM_USER',
    'b55045f7-edda-44a0-9eda-2ecdf573cef5': 'SUPERVISOR',
    '1d321eb9-26cb-40ab-bd9a-5bebb67fb07f': 'USER',
    '641566fd-4da6-44f4-80dd-31e8e9ece322': 'VIEW_ONLY',
    'd738c18f-9ecf-4f49-ae11-4cba2d6e5d31': 'ADMIN',
  };

  async function onLoad() {
    try {
      const loggedInUser = await Auth.currentAuthenticatedUser();
      const loggedInUserAttributes = await Auth.userAttributes(loggedInUser);
      const loggedInUserGroups = loggedInUserAttributes.find(attribute => attribute.Name === 'custom:group')?.Value;
      if (loggedInUserGroups.length !== 36) {
        if (loggedInUserGroups.includes('d738c18f-9ecf-4f49-ae11-4cba2d6e5d31')) {
          setAdminGroup();
        } else if (loggedInUserGroups.includes('b55045f7-edda-44a0-9eda-2ecdf573cef5')) {
          setSupervisorGroup();
        } else if (loggedInUserGroups.includes('1d321eb9-26cb-40ab-bd9a-5bebb67fb07f')) {
          setUserGroup();
        } else if (loggedInUserGroups.includes('2838edf4-ab8e-4506-9427-9f04a2777e9b')) {
          setSecurityUserGroup();
        } else {
          setViewOnlyGroup();
        }
      } else {
        setCurrentGroup(SECURITY_GROUPS[loggedInUserGroups]);
      }
    } catch (e) {
      if (e !== 'No current user') {
        console.log('Error', e);
      }
    }
  }

  useEffect(() => {
    onLoad();
  }, []);

  const renderDashboard = () => (!getTabs().some(tab => location.pathname === tab.pathname) ? (
    <Redirect to={ getTabs()[0].pathname } />
  ) : (
    <>
      <div className={ classes.root }>
        <div className={ classes.navbar }>
          <div className={ classes.brandContainer }>
            <img className={ classes.logo } src={ AdminPortalIconWhite } alt='Admin Portal Logo' />
            <img className={ classes.logo2 } src={ DataCentresLogoWhite } alt='Datacom DataCentres Logo' />
          </div>
          <div className={ classes.divider } />
          <Tabs
            orientation='vertical'
            variant='scrollable'
            value={ tabIndex }
            aria-label='Navigation Tabs'
            className={ classes.tabs }
          >
            {getTabs().map((tab, index) => (
              !tab.hide && (
                <Tab
                  key={ tab.pathname }
                  disabled={ !tab.component }
                  label={ tab.label }
                  icon={ icons[tab.icon] }
                  classes={ {
                    wrapper: classes.tabWrapper,
                    labelIcon: classes.tabLabelIcon,
                    root: classes.tabRoot,
                  } }
                  className={ classes.tab }
                  onClick={ () => {
                    history.push(tab.pathname);

                    if (tab.label === SECURITY) {
                      setSecurityTab();
                    } else {
                      setOperational();
                    }
                  } }
                  { ...a11yProps(index) }
                />
              )
            ))}
            <div className={ classes.divider } />
            <Tab
              label='Log out'
              icon={ <LogoutIcon /> }
              classes={ {
                wrapper: classes.tabWrapper,
                labelIcon: classes.tabLabelIcon,
                root: classes.tabRoot,
              } }
              className={ classes.tab }
              onClick={ signOut }
            />
          </Tabs>
          <Tooltip title={ darkMode ? 'Use Light Theme' : 'Use Dark Theme' }>
            <Fab
              onClick={ toggleTheme }
              className={ classes.fab }
              color='primary'
              size='small'
            >
              {darkMode ? <Brightness7 className={ classes.white } /> : <Brightness4Outlined />}
            </Fab>
          </Tooltip>
          <p className={ classes.versionInfo }>
            {`©${new Date().getFullYear()} DATA CENTRES ADMIN PORTAL`}
            <br />
            VERSION 1.4.7
          </p>
          <div className={ classes.navbarBackground } />
        </div>
        <div className={ classes.routeContainer }>
          {getTabs().map(tab => !tab.hide && (
            <Route key={ tab.pathname } path={ tab.pathname }>
              <ErrorBoundary fallback={ <Error /> }>
                <Suspense fallback={ <Loading /> }>
                  {tab.component ? renderTabComponent(tab.component) : <Redirect to={ getTabs()[0].pathname } />}
                </Suspense>
              </ErrorBoundary>
            </Route>
          ))}
        </div>
      </div>
    </>
  )
  );

  return renderDashboard();
};

TabLayout.defaultProps = {
  darkMode: false,
  selectedSecurityGroup: null,
  selectedDashboard: null,
};

TabLayout.propTypes = {
  darkMode: PropTypes.bool,
  toggleTheme: PropTypes.func.isRequired,
  setSignOut: PropTypes.func.isRequired,
  setSecurityGroup: PropTypes.func.isRequired,
  selectedSecurityGroup: PropTypes.string,
  setDashboard: PropTypes.func.isRequired,
  selectedDashboard: PropTypes.string,
};

const mapStateToProps = state => (
  {
    selectedSecurityGroup: securityGroupSelector(state),
    selectedDashboard: dashboardSelector(state),
  }
);

const mapDispatchToProps = dispatch => (
  {
    setSecurityGroup: event => dispatch(setSecurityGroup(event)),
    setDashboard: event => dispatch(setDashboard(event)),
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(TabLayout);

import { lazy } from 'react';

const AccessRequestTable = lazy(() => (import('../containers/AccessRequestTable')));
const SecurityAccessRequestTable = lazy(() => (import('../containers/SecurityAccessRequestTable')));
const SecurityKeyRequestTable = lazy(() => (import('../containers/SecurityKeyRequestTable')));
const ServiceRequestTable = lazy(() => (import('../containers/ServiceRequestTable')));
const KeyRequestTable = lazy(() => (import('../containers/KeyRequestTable')));
const NotificationCentre = lazy(() => import('../components/Notification/NotificationCentre'));
const DocumentCentre = lazy(() => import('../components/DocumentCentre/DocumentCentre'));
const IncidentRequestTable = lazy(() => (import('../containers/IncidentRequestTable')));
const ChangeRequestTable = lazy(() => (import('../containers/ChangeRequestTable')));
const PromptPage = lazy(() => import('./PromptPage'));

export const ADMIN_TABS = [
  {
    label: 'Access Requests',
    component: AccessRequestTable,
    icon: 0,
    pathname: '/a-access-requests',
  },
  {
    label: 'Security',
    component: SecurityAccessRequestTable,
    icon: 1,
    pathname: '/a-security-access-requests',
  },
  {
    label: 'Service Requests',
    component: ServiceRequestTable,
    pathname: '/a-service-requests',
    icon: 2,
  },
  {
    label: 'Change Requests',
    component: ChangeRequestTable,
    pathname: '/a-change-requests',
    icon: 3,
  },
  {
    label: 'Incidents',
    component: IncidentRequestTable,
    pathname: '/a-incident-requests',
    icon: 4,
  },
  {
    label: 'Data Centre Keys',
    component: KeyRequestTable,
    pathname: '/a-key-requests',
    icon: 5,
  },
  {
    label: 'Notifications',
    component: NotificationCentre,
    pathname: '/a-notifications',
    icon: 6,
  },
  {
    label: 'Document Centre',
    component: DocumentCentre,
    pathname: '/a-documents-centre',
    icon: 7,
  },
];

export const OPERATIONS_TABS = [
  {
    label: 'Access Requests',
    component: AccessRequestTable,
    pathname: '/o-access-requests',
    icon: 0,
  },
  {
    label: 'Security',
    component: SecurityAccessRequestTable,
    icon: 1,
    pathname: '/o-security-access-requests',
  },
  {
    label: 'Service Requests',
    component: ServiceRequestTable,
    pathname: '/o-service-requests',
    icon: 2,
  },
  {
    label: 'Change Requests',
    component: ChangeRequestTable,
    pathname: '/o-change-requests',
    icon: 3,
  },
  {
    label: 'Incidents',
    component: IncidentRequestTable,
    pathname: '/o-incident-requests',
    icon: 4,
  },
  {
    label: 'Data Centre Keys',
    component: KeyRequestTable,
    pathname: '/o-key-requests',
    icon: 5,
  },
  {
    label: 'Notifications',
    component: NotificationCentre,
    pathname: '/o-notifications',
    icon: 6,
  },
];

export const SECURITY_TABS = [
  {
    label: 'Access Requests',
    component: AccessRequestTable,
    pathname: '/s-access-requests',
    icon: 0,
  },
  {
    label: 'Security',
    component: SecurityAccessRequestTable,
    icon: 1,
    pathname: '/s-security-access-requests',
  },
  {
    label: 'Service Requests',
    component: ServiceRequestTable,
    pathname: '/s-service-requests',
    icon: 2,
  },
  {
    label: 'Change Requests',
    component: ChangeRequestTable,
    pathname: '/s-change-requests',
    icon: 3,
  },
  {
    label: 'Incidents',
    component: IncidentRequestTable,
    pathname: '/s-incident-requests',
    icon: 4,
  },
  {
    label: 'Data Centre Keys',
    component: SecurityKeyRequestTable,
    pathname: '/s-key-requests',
    icon: 5,
  },
  {
    label: 'Notifications',
    component: NotificationCentre,
    pathname: '/s-notifications',
    icon: 6,
  },
];

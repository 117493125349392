import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';// defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './reducers/root';
import DevTools from './DevTools';

/**
 *  https://github.com/reduxjs/redux-devtools/blob/master/docs/Walkthrough.md#manual-integration
 */

const devMode = process.env.NODE_ENV === 'development';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['app'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middlewares = [
  thunk, // lets us dispatch() functions
];

// https://redux.js.org/api-reference/applymiddleware#tips
if (devMode) {
  const loggerMiddleware = createLogger(); // neat middleware that logs actions
  middlewares = [...middlewares, loggerMiddleware];
}

const enhancer = devMode ? compose(
  // Middleware you want to use in development:
  applyMiddleware(...middlewares),
  // Enable Redux DevTools with the monitors you chose
  DevTools.instrument(),
) : applyMiddleware(...middlewares);

export default function configureStore(initialState) {
  // Note: only Redux >= 3.1.0 supports passing enhancer as third argument.
  // See https://github.com/reactjs/redux/releases/tag/v3.1.0
  const store = createStore(persistedReducer, initialState, enhancer);
  const persistor = persistStore(store);
  return { store, persistor };
}

import { Auth } from 'aws-amplify';
import momentTz from 'moment-timezone';
import moment from 'moment';

// eslint-disable-next-line max-len
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const numberOnlyRegex = /^\+[1-9]\d{0,1}-?\d{3,4}-?\d{4,6}$/;

export const numberRegex = /^[0-9]*$/;
export const nonZeroNumberRegex = /^[1-9][0-9]*$/;
export const decimalRegex = /^\d*\.?\d*$/;
export const onlyNonZeroDecimalRegex = /^(?!0(\.0)?$)(\d)*(\.(5|0))$/;
export const fullDepthOptions = /^(?:Yes|No|yes|no)$/

export const validateAuth = (success, failure) => Auth.currentSession()
  .then(success)
  .catch(failure);

export const convertInputTimezone = (momentDateTime, timezone) => (
  momentDateTime ? momentTz.tz(momentDateTime.format('YYYY-MM-DD HH:mm:ss'), timezone) : undefined
);

export const convertToLocalTimezone = (momentDateTime, timezone) => (
  momentDateTime ? momentTz.tz(momentDateTime, timezone || moment.tz.guess()) : undefined
);
